import useAuth from './useAuth';
import useLocalStorage from './useLocalStorage';
// ----------------------------------------------------------------------

const storageKey = 'trapped-organization';
export default function useOrg() {
  const { user } = useAuth();
  const allowedOrgIds = user?.allowedOrgIds;
  const [value, setValueInStorage] = useLocalStorage(storageKey, allowedOrgIds?.[0]);

  const handleOrgChange = (orgId) => {
    if(!allowedOrgIds.includes(orgId)) {
      throw Error("orgId is not allowed");
    }
    setValueInStorage(orgId);
    window.location.reload();
  };

  return {
    onOrgChange: handleOrgChange,
    currentOrgId: value,
    allowedOrgIds,
  };
}
