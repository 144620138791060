import { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
// @mui
import { MenuItem, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
// hooks

// components
import MenuPopover from '../../../components/MenuPopover';
import useOrg from '../../../hooks/useOrg';

// ----------------------------------------------------------------------

export default function LocationPopover() {
  
  const GET_LOCATIONS = gql`
    query {
      locations {
        id,
        location_name
      }
    }  
  `;

  const { currentOrgId, allowedOrgIds, onOrgChange } = useOrg();
  const [open, setOpen] = useState(null);
  const { loading, error, data } = useQuery(GET_LOCATIONS);

  const orgIdToName = new Map(data?.locations?.map((obj) => [obj.id, obj.location_name]));

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Button
        color="secondary"
        variant="text"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleOpen}
        startIcon={<EditLocationAltIcon />}
        endIcon={<KeyboardArrowDownIcon />}
      >
        { orgIdToName.get(currentOrgId) }
      </Button>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allowedOrgIds.map((id) => (
            <MenuItem
              key={id}
              selected={id === currentOrgId}
              onClick={() => onOrgChange(id)}
            >
              {orgIdToName.get(id)}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
