import { GraphQLClient, gql } from 'graphql-request';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash'
import { dispatch } from '../store';
import { ExternalAuthContext } from '../../contexts/ExternalAuthContext';

const endpoint = "https://trapped-portal.hasura.app/v1/graphql";
const gqlClient = new GraphQLClient(endpoint);

const LIST_MEMBERS = (limit, offset, locationId, phonePrefix) => gql`
query {
    members(where: {phone_number: {_like: "${phonePrefix}%"}, signup_location_id: {_eq: ${locationId}}}, limit: ${limit}, offset: ${offset}) {
      name: full_name
      id
      email
      phoneNumber: phone_number
    }
    members_aggregate(where: {phone_number: {_like: "${phonePrefix}%"}, signup_location_id: {_eq: ${locationId}}}) {
      aggregate {
        count
      }
    }
}`;

const initialState = {
  isLoading: false,
  error: null,
  members: [],
  total: 0
};

const slice = createSlice({
  name: 'membershipDirectory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    listMembersSuccess(state, action) {
        state.isLoading = false;
        state.members = action.payload.members;
        state.total = action.payload.total;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function listMembers(limit, offset, locationId, phonePrefix = "") {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const accessToken = await ExternalAuthContext.getAccessTokenSilently()();
      const requestHeaders = {
        Authorization: `Bearer ${accessToken}`
      };
      const query = LIST_MEMBERS(limit, offset, locationId, phonePrefix);
      const response = await gqlClient.request(query, undefined, requestHeaders);
      const flatten = response.members;
      const result = {members: flatten, total: response.members_aggregate.aggregate.count};
      dispatch(slice.actions.listMembersSuccess(result));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
