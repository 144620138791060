// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ name, ...other }) {
  const avatar = createAvatar(name);

  return (
    <Avatar
      color={avatar.color}
      {...other}
    >
      {avatar.name}
    </Avatar>
  );
}
